.client-center { 
  display: flex;
  align-items: center; 
  justify-content: flex-end;
}
.icon-container-center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
}

.icon-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  color: gray;
  text-align: center;
}