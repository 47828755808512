.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -23px;
    width: 30px;
    height: 30px;
    border-top: 16px solid #708991;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }