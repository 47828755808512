.c3-axis-x text,
.c3-axis-y text {
    fill: #c8c8c8;
    font-size: 12px;
}

.c3-axis-y path,
.c3-axis-y line {
    stroke: #c8c8c8;
}

.c3-axis-x path,
.c3-axis-x line {
    stroke: #c8c8c8;
}
.c3-line {
    stroke-width: 5px;
}
.c3 .c3-axis-x .tick:last-of-type text {
    transform: translate(-8px, 0) !important;
}

.chart_wrapper {
    width: fit-content;
    line-height: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1A1A1A;
}

.chart_wrapper .left__title {
    width: fit-content;
    font-size: 1rem;
    color: #38393d;
    font-weight: 600;
    font-family: 'Spline Sans';
}

.chart_wrapper .left__title .left__title_hover:hover {
    text-decoration: underline;
}

.chart_wrapper .left__subTitle {
    width: fit-content;
    font-size: 0.75rem;
    color: #bdbdbd;
    font-weight: 500;
    font-family: 'Spline Sans';
}

.chart_wrapper .left__subTitle .left__subTitle_hover:hover {
    text-decoration: underline;
}

@media only screen and (max-height: 754px) {
    .chart_wrapper {
        line-height: 1.2;
    }
}

.canvasjs-chart-canvas {
    width: 100% !important;
}
