.riskAnalyticFilter_Button {
  width: 110px;
  z-index: 10;
}

.riskAnalyticFilter_OKButton {
  background: #303fa0;
  margin-right: 140px !important;
  margin-bottom: 10px !important;
}
.riskAnalyticFilter_OKButton:hover {
  color: white;
  background: #303fa0;
}
.riskAnalyticFilter_CancelButton {
  margin-left: 20px !important;
  margin-right: 30px !important;
  margin-bottom: 10px !important;
}
.date-range-picker-dialog {
  width: 788px;
}

.date-range-picker-dialog .MuiPaper-elevation5 {
  box-shadow: none;
}

div[class^="materialui-daterange-picker"] {
  box-shadow: none;
}

.riskAnalyticFilter_ApplyButton {
  width: 100%;
  z-index: 10;
  background-color: #284394 !important;
  margin-top: 20px !important;
}
.riskAnalyticFilter_ApplyButton > span {
  font-family: "Spline Sans" !important;
  text-transform: capitalize;
  color: white;
}

.eventReviewFilter_ApplyButton {
  width: 100%;
  z-index: 10;
  background-color: #3f51b5 !important;
  margin-top: 20px !important;
}
.eventReviewFilter_ApplyButton:hover {
  background-color: #284394 !important;
}

.eventReviewFilter_ApplyButton > span {
  font-family: "Spline Sans" !important;
  text-transform: capitalize;
  color: white;
}
