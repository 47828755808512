button:focus {
    outline: none !important;
}
.text-bold {
    font-family: 'Spline Sans' !important;
    font-weight: normal !important;
}
.eventListReview {
    margin-top: 15px;
    overflow-y: scroll;
    max-height: 650px;
    direction: rtl;
}
.eventListReview::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.eventListReview::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
    margin-right: 5px;
}

.Toastify__toast--success {
    border-left: 5px solid rgba(0, 201, 109, 1) !important;
}

.Toastify__close-button {
    color: black;
}

.eventListReview::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: #efefef;
}
.app-version {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
html {
    background-color: #f5f6fa;
}
body > #root {
    position: relative;
}
.display-none {
    display: none !important;
}

.display-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.full-screen {
    height: 100vh;
    width: 100vw;
}

.display-between {
    display: flex;
    justify-content: space-between;
}

.absolute-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.font-myriad-regular {
    font-family: Spline Sans !important;
}

.font-myriad-semibold {
    font-family: Spline Sans !important;
}

.text-none {
    text-transform: none;
}

.cursor-pointer {
    cursor: pointer;
}

.MuiCircularProgress-colorPrimary {
    color: #00c57b !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    color: #4e4e4e;
    background-color: #f8f9fa;
    font-family: 'Spline Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    height: 40px;
}

.swal2-container.swal2-center {
    z-index: 1300;
}

.MuiButton-outlined.green-outlined-button {
    height: 48px;
    border-radius: 100px;
    color: #00c57b;
    border-color: #00c57b;
}

a:visited {
    color: #38a169 !important;
}

a {
    color: #00c57b !important;
}
