.argument-axis-value-container {
  height: 80px;
  position: relative;
}
.value-axis-title {
  position: absolute;
  top: 45px;
  left: 5px;
  font-weight: 500;
}
.argument-axis-value-container .argument-axis-label {
  transform: rotate(-60deg);
  position: absolute;
  top: 10px;
  font-size: 12px;
}
.argument-axis-value-container.grid .argument-axis-label {
  font-size: 10px;
  margin-left: 8px;
}
#center-axis-container path {
  opacity: 1;
}
.risk-analytic {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.risk-analytic .shapes,
.risk-analytic .types {
  font-size: 20px;
  border-radius: 8px;
  display: inline-flex;
  position: relative;
  background: #dcdfed;
}
.risk-analytic .shapes,
.risk-analytic .types.f-right {
  float: right;
}
.risk-analytic .shapes .shape,
.risk-analytic .types .type {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  color: #234099;
  z-index: 2;
}

.risk-analytic .types .type {
  width: 113px;
  font-size: 16px;
}
.risk-analytic .types .type > svg {
  font-size: 20px;
  margin-right: 5px;
}
.risk-analytic .shapes .active,
.risk-analytic .types .active {
  box-sizing: border-box;
  position: absolute;
  width: 48px;
  height: 48px;
  background: white;
  top: 0;
  left: 0;
  z-index: 1;
  transition: left 500ms;
  color: #284394;
}

.risk-analytic .types .active {
  width: 113px;
}

.risk-analytic .shapes .shapes-list,
.risk-analytic .shapes .shapes-grid,
.risk-analytic .types .shapes-map,
.risk-analytic .types .shapes-graph {
  color: #284394;
}

.risk-analytic .shapes .shapes-list.active,
.risk-analytic .types .shapes-map.active {
  left: 0;
  border-radius: 8px;
}
.risk-analytic .shapes .shapes-grid.active,
.risk-analytic .types .shapes-graph.active {
  left: 48px;
  border-radius: 8px;
}
.risk-analytic .types .shapes-graph.active {
  left: 113px;
}

.risk-title {
  font-weight: 600;
  font-size: 32px;
  color: #252626;
  font-style: normal;
  line-height: 40px;
  display: flex;
  align-items: flex-end;
  float: left;
}

.risk-analytic .MuiButton-startIcon {
  /* margin: 0; */
}

.risk-analytic-left {
  background: #f0f1f5;
  max-width: 450px;
  min-width: 258px;
  padding: 18px;
}

.risk-analytic-right {
  background: #f5f6fa;
}

.wrap-shapes {
  margin-bottom: 15px;
}

.chart-container-top {
  position: relative;
  height: 70px;
}
.chart-container-top .chart-legend {
  position: absolute;
  right: 0px;
  top: 25px;
}

.chart-container-top .chart-legend > div {
  display: inline-block;
}

.chart-container-top .chart-legend .chart-legend-dot-orange {
  width: 7px;
  height: 7px;
  background-color: #ed8532;
  margin-bottom: 2px;
}

.chart-container-top .chart-legend .chart-legend-dot-blue {
  width: 7px;
  height: 7px;
  background-color: #4292d1;
  margin-bottom: 2px;
  margin-left: 15px;
}

.chart-container-top .chart-legend .chart-legend-label {
  margin-left: 10px;
}

.chart-container-top h4.chart-title {
  color: #646576;
  font-family: "Spline Sans";
  font-weight: normal;
  position: absolute;
  left: 0px;

  font-size: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 55%;
  display: block;
}

.chart-container-top .chart-axisy {
  position: absolute;
  left: 0px;
  top: 75px;
  color: #646576;
  font-family: "Spline Sans";
  font-weight: normal;
}

.chart-container-top .chart-axisy2 {
  position: absolute;
  right: 0px;
  top: 75px;
  color: #646576;
  font-family: "Spline Sans";
  font-weight: normal;
}
.chart-area-module {
  margin-bottom: 0px !important;
}
.chart-area-module.grid:nth-child(odd) {
  margin-left: 20px;
}
.chart-container-top.grid h4.chart-title {
  font-size: 20px;
}

.chart-container-top.grid .chart-legend {
  top: 50px;
}
.chart-container-top.grid .chart-legend .chart-legend-label {
  font-size: 12px;
}
.chart-container-top.grid .chart-axisy,
.chart-container-top.grid .chart-axisy2 {
  font-size: 12px;
}
.chart-container-top.grid .chart-legend .chart-legend-dot-blue,
.chart-container-top.grid .chart-legend .chart-legend-dot-orange {
  width: 5px;
  height: 5px;
}
.no-data-found {
  text-align: center;
  width: 100%;
  line-height: 200px;
}
