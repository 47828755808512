.events_reviews__filter {
    width: 100%;
}
.events_reviews__filter .sort {
    float: left;
    min-width: 120px;
    margin-left: 40px;
    margin-bottom: 20px;
    font-size: 12px;
}
.events_reviews__filter .sort:last-child {
    min-width: 275px;
    float: right;
}
.events_reviews__filter .sort .MuiSelect-selectMenu {
    font-size: 14px;
    font-family: 'Spline Sans';
    font-weight: normal;
    color: rgba(0, 0, 0, 0.54);
}

.state-checkbox-inline {
    height: 20px;
}
.filter-date-picker-range > label,
.filter-title {
    font-size: 1em !important;
    font-family: 'Spline Sans';
    font-weight: normal;
}
.filter-search {
    padding: 2px 15px;
    border-radius: 8px;
    border-color: transparent;
    margin-top: 15px;
    min-width: 240px;
}

.filter-date-picker-range {
    width: 170px;
}
.filter-label {
    font-size: 24px;
    color: #234099;
    line-height: 65px;
}
.selectedDateValue {
    font-style: italic;
}

.review_events {
    width: 100%;
}
.review_events .review_events__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #234099;
    margin-top: 8px;
    padding: 0 5px;
}
.title {
    font-size: 24px;
    font-family: 'Spline Sans';
    font-weight: normal;
    color: #234099;
}
.review_events .review_events__top .title {
    font-size: 24px;
    font-family: 'Spline Sans';
    font-weight: normal;
}
.review_events .review_events__top .shapes {
    font-size: 14px;
    border: 1px solid #dcdfed;
    background: #dcdfed;
    border-radius: 8px;
    display: flex;
    position: relative;
}
.review_events .review_events__top .shapes .shape {
    width: 86px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding-top: 3px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 600;
    z-index: 2;
    line-height: 30px;
}
.review_events .review_events__top .shapes .shapes__low {
    color: #234099;
}
.review_events .review_events__top .shapes .shapes_med {
    color: #e17a37;
}
.review_events .review_events__top .shapes .shapes__high {
    color: #db2f27;
}
.review_events .review_events__top .shapes .shapes__active {
    border: 1px solid #fff;
    box-sizing: border-box;
    position: absolute;
    width: 86px;
    height: 30px;
    background: #fff;
    top: 0;
    left: 0;
    border-radius: 8px;
    z-index: 1;
    transition: left 500ms;
}

.review_events .review_events__top > .shapes__button {
    width: 86px;
    height: 30px;
    background-color: white;
    border-radius: 8px;
    color: red;
    line-height: 30px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
}
.review_events .review_events__top > .shapes__button.dismiss {
    color: white;
    background-color: red;
}

.comment-actions {
    width: 100%;
    position: relative;
}

.comment-actions .comment-input {
    float: left;
    width: calc(100% - 60px);
    height: 46px;
    border-radius: 8px;
    border: none;
    padding: 10px;
    font-size: 16px;
}

.comment-actions .comment-button {
    width: 56px;
    height: 43px;
    float: left;
    margin-top: 2px;
    margin-left: 4px;
    cursor: pointer;
}
.delete-icon {
    color: red;
    width: 0.8em !important;
    height: 0.8em !important;
}
.reset-filter {
    color: #445b6d;
    cursor: pointer;
    margin-left: 5px;
    font-size: 15px !important;
    margin-top: 25px;
}
.overlay-event-object {
    border: 1.5px solid #e12b2b;
    background: linear-gradient(2.66deg, rgba(225, 43, 43, 0.1) 30.2%, rgba(225, 43, 43, 0) 137.34%);
    position: absolute;
    border-radius: 4px;
    z-index: 1;
    cursor: pointer;
}

.overlay-person {
    border-radius: 4px;

    position: absolute;
    z-index: 3;
    cursor: pointer;
}

.overlay-object {
    --b: 1.5px; /* thickness of the border */
    --c: #05bd32; /* color of the border */
    --w: 12px; /* width of border */

    border-radius: 4px;

    position: absolute;
    z-index: 3;
    cursor: pointer;
    border: var(--b) solid transparent; /* space for the border */
    background: linear-gradient(var(--c), var(--c)) top left, linear-gradient(var(--c), var(--c)) top left,
        linear-gradient(var(--c), var(--c)) bottom left, linear-gradient(var(--c), var(--c)) bottom left,
        linear-gradient(var(--c), var(--c)) top right, linear-gradient(var(--c), var(--c)) top right,
        linear-gradient(var(--c), var(--c)) bottom right, linear-gradient(var(--c), var(--c)) bottom right;
    background-size: var(--b) var(--w), var(--w) var(--b);
    background-origin: border-box;
    background-repeat: no-repeat;
}

.overlay-object.Person {
    --c: #000e5f; /* color of the border */

    /* border: 1px solid #000e5f; */
}
.overlay-object.Goods {
    --c: #ffc107; /* color of the border */
    /* border: 1px solid #ffc107; */
}
.overlay-object.active-json {
    --c: #ffc107; /* color of the border */
    /* border: 1px solid #ffc107; */
}
#videoContainer {
    overflow: hidden;
}
#videoContainer:fullscreen .overlay-object,
#videoContainer:fullscreen .overlay-event-object {
    border-width: 2px;
}
.videoContainerBoard {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    pointer-events: none;
}
/* video::-webkit-media-controls-fullscreen-button{display: none;} */

.overlay-object:hover,
.overlay-event-object:hover {
    --b: 2.5px; /* thickness of the border */
    --c: #04f740;
    /* border: 2px solid #04f740; */
    cursor: pointer;
}
#videoContainer {
    overflow: hidden;
}

.icon-hover button:hover {
    background-color: rgba(0, 197, 123, 0.04);
    border-radius: 50%;
}

.icon-hover button {
    width: 38px;
    height: 38px;
}

.delete_modal__text {
    font-family: 'Spline Sans';
}

.delete_modal__title {
    font-family: 'Spline Sans' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 150%;
    color: #1a1a1a !important;
}

.delete_modal__content {
    justify-content: flex-start !important;
    padding: 10px 28.8px !important;
}

.swal2-html-container {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    font-family: 'Spline Sans' !important;
}

.delete_modal__confirm {
    color: #ffffff !important;
    border-radius: 100px !important;
    font-family: 'Spline Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 145% !important;
    text-transform: capitalize !important;
    height: 40 !important;
    background: #00c57b !important;
    box-shadow: none !important;
}

.delete_modal__confirm:hover {
    background: #007045;
}

.delete_modal__cancel {
    color: #4e4e4e !important;
    border-radius: 100px !important;
    font-family: 'Spline Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 145% !important;
    text-transform: capitalize !important;
    height: 40;
    background: #ffffff !important;
    box-shadow: none !important;
    border: 1px solid #c6cbcc !important;
    width: 189px !important;
}

.delete_modal__loader {
    color: #00c57b;
}

.swal2-popup .swal2-header {
    align-items: flex-start !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
    color: #ffffff !important;
    border-radius: 100px !important;
    font-family: 'Spline Sans' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 145% !important;
    text-transform: capitalize !important;
    height: 40 !important;
    box-shadow: none !important;
}

.swal2-popup #swal2-content {
    font-family: 'Spline Sans';
    text-align: left !important;
}

.swal2-icon.swal2-warning {
    color: #e12b2b !important;
    border-color: #e12b2b !important;
}

.swal2-icon.swal2-success {
    color: #00c57b !important;
    border-color: #00c57b !important;
}

.hide-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
    width: 0;
    display: none;
}

.lists-arrow-icon {
    right: 13px !important;
}
