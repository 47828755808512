
.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.wrapper .wrapper__item {
    width: 24%;
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.wrapper .wrapper__item .info {
    padding: 20px 20px 0 20px;
}

.wrapper .wrapper__item .info .info__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper .wrapper__item .info .info__main .left .left__name {
    font-size: 16px;
    font-family: "Spline Sans";
    font-weight: normal;
}

.wrapper .wrapper__item .info .info__main .left .left__title {
    font-size: 1rem;
    font-family: "Spline Sans";
    font-weight: bold;
}

.wrapper .wrapper__item .info .info__main .left .left__subtitle {
    font-size: 0.75rem;
    font-family: "Spline Sans";
    font-weight: normal;
}

.wrapper .wrapper__item .info .info__main .left .left__others {
    display: flex;
    align-items: center;
}

.wrapper .wrapper__item .info .info__main .left .left__others .icon {
    color: #7ED321;
}

.wrapper .wrapper__item .info .info__main .left .left__others .icon.trending-down {
    color: #FF4B4B;
}

.wrapper .wrapper__item .info .info__main .left .left__others .icon.no-alternation {
    width: 24px;
    border-top: 2px solid #D8D8D8;
    margin-right: 10px;
}

.wrapper .wrapper__item .info .info__main .left .left__others .percent {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 5px;
}

.wrapper .wrapper__item .info .info__main .left .left__others .status {
    font-size: 12px;
}

.wrapper .wrapper__item .info .info__main .right {
    font-size: 36px;
}

.wrapper .wrapper__item .info .info__delimiter {
    border-top: 1px solid #979797;
    margin: 10px 0 12px 0;
    opacity: .2;
}

.wrapper .wrapper__item .chart-wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 2px;
}

.wrapper .wrapper__item .line-gradient {
    width: 100%;
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, #fffffdab, #faf1ec30);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    pointer-events: none;
}

.wrapper .wrapper__item .chart-wrapper.chart-wrapper--no-alter {
    position: absolute;
    bottom: 0;
}
.recharts-tooltip-wrapper {
    top: -5px !important;
}
.recharts-tooltip-wrapper > div{
    border-radius: 8px;
    top: -5px;
}

.table-striped:nth-child(2n+0){
    background-color: #F8F9FA !important;
}
