* {
    font-family: 'Spline Sans';
}
body {
    overflow-x: hidden;
}
header .MuiTab-wrapper,
header .MuiButton-label {
    font-family: 'Spline Sans';
    color: #252626;
}
header .Mui-selected .MuiTab-wrapper {
    color: #234099;
}

header .MuiTabs-indicator {
    max-width: 80px;
    margin-left: 40px;
}

.MuiIconButton-root:hover {
    background-color: inherit;
}

/* .MuiGrid-spacing-xs-1 > .MuiGrid-item  {
    padding: 0px !important;
} */

.withRouter\(Mobile\)-itemText-32 .MuiListItemText-secondary,
.MuiListItemText-secondary {
    overflow: hidden;
    max-width: 60%;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-style: 13px;
}
.action_button {
    width: calc(100vw - 32px);
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 0);
}

.main-backdrop {
    z-index: 9999 !important;
}

.event-card-hover {
    overflow: auto;
    height: 90%;
    direction: ltr;
    overflow-y: auto !important;
    overflow: auto !important;
    -ms-overflow-style: auto !important;
    scrollbar-width: auto !important;
    padding-right: 10px;
}

.event-card-hover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f6f7f9;
}

.event-card-hover::-webkit-scrollbar {
    width: 8px !important;
    background-color: #f6f7f9;
    margin-right: 5px;
}

.event-card-hover::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
    background-color: rgba(142, 157, 199, 0.2);
}
.event-card-hover > div {
    direction: ltr;
}

.exception {
    height: 100vh;
}

.errorAvatar {
    height: auto;
}

.grid-center {
    display: grid;
    place-items: center;
}

.errorTitle {
    color: #434e59;
    font-size: 72px;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
}

.errorDescription {
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
}
