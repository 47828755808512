.event-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    box-shadow: 1px 1px 7px 1px #cbced4;
    border-radius: 7px;
}
.description {
    height: 3em;
    color: #4C4C4C;
    margin-top: 0;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.root {
    display: flex;
    box-sizing: border-box;
    width: 60%;
    background: white;
    padding: 20px 20px 30px 40px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    cursor: pointer;
    z-index: 1;
}
.darkColor {
    color: #4C4C4C;
}

.content {
    width: 100%;
}

.content__reply-icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.content__unflagged-icon svg {
    color: #234099;
}

.content__flagged-icon svg {
    color: #f2632e;
}
.content__search-icon svg {
    color: #234099;
    margin-left: 10px;
}

.content__permalink-icon svg {
    color: #234099;
    -ms-transform: rotate(135deg); /* IE 9 */
    transform: rotate(135deg);
    margin-left: 10px;
}

.content__reply-icon svg:nth-child(2) {
    margin-left: 15px;
}

.content__reply-icon svg:nth-child(3) {
    margin-left: 15px;
}
.contentType {
    display: flex;
    align-items: center;
}
.contentTypeMajor {
    color: #4C4C4C;
    margin: 0;
    font-family: "Spline Sans";
    font-weight: normal;
}
.contentTypeDot {
    color: #4C4C4C;
    font-weight: 900;
    width: 3px;
    height: 3px;
    background-color: #4C4C4C;
    border-radius: 50%;
    margin: 0 5px;
}
.contentTypeSub {
    color: #4C4C4C;
    font-family: "Spline Sans";
    font-weight: normal;
    margin-bottom: 0;
}
.description {
    color: #4C4C4C;
    margin-top: 0;
    margin-bottom: 20px;
}
.svg20Icon {
    height: 20px !important;
}
.contentExtraInfo {
    display: flex;
}
.contentExtraInfo__icon-num {
    display: flex;
    align-items: center;
}
.extraInfoContent {
    color: #4C4C4C;
    margin: 0;
    font-family: "Spline Sans";
    font-weight: normal;

    margin-top: 4px;
}
.marginLeft25 {
    margin-left: 25px;
}
.extraInfoStatus {
    display: flex;
    align-items: center;
}
.extraInfoStatusIcon {
    width: 10px;
    margin-right: 5px;
}

.iframe-wrapper {
    width: 40%;
    height: 225px;
    position: relative;
}

.iframe-wrapper-visual-screen {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
}

.iframe-wrapper .iframe-wrapper__play-icon {
    background: #FFFFFF;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    opacity: 0;
    transition: 800ms opacity;
}

.iframe-wrapper .iframe-wrapper__play-icon .play-icon__ic {
    color: #234099;
}

.iframe {
    color: white;
}

.iframe video {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.iframe > div > iframe {
    border-radius: 6px;
}

.iframe ~ video {
    height: 98% !important;
}

.lazyload-wrapper > .error-video {
    width: 100%;
    color: #aaaaaa;
    background: #f5f5f9;
    text-align: center;
    position: absolute;
    top: 50%;
}
