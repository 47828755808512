.user-setting-wrapper {
    padding: 24px 32px;
}
.user-setting-action > button {
    width: 100px;
    margin: 0;
}
.user-setting-wrapper > div {
    width: 100%;
    height: calc(100vh - 215px);
    overflow: auto;
    padding: 8px 32px;
}
.user-setting-wrapper .menu-title {
    color: #333333;
    font-size: 1.125rem;
    font-weight: 600;
    font-family: Spline Sans;
}
.user-setting-wrapper .left-menu {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
}
.user-setting-wrapper .left-menu ul {
    list-style: none;
    padding: 20px 20px 0px 20px;
    line-height: 32px;
}
.user-setting-wrapper .left-menu ul li {
    border-bottom: 1px solid lightgrey;
    padding: 10px 0px;
    color: #252626;
    font-size: 16px;
    cursor: pointer;
    position: relative;
}
.user-setting-wrapper .left-menu ul li:last-child {
    border-bottom: none;
}
.user-setting-wrapper .left-menu ul li .activeBar {
    display: none;
}
.user-setting-wrapper .left-menu ul li.active .activeBar {
    position: absolute;
    left: -20px;
    top: 5px;
    width: 8px;
    height: 36px;
    background-color: #d96427;
    display: block;
}
.user-setting-wrapper .content-wrapper {
    width: 100%;
}
.user-setting-wrapper .content-wrapper span {
    line-height: 52px;
    font-size: 16px;
    margin-right: 10px;
}

.user-setting-wrapper .content-wrapper .settingInput {
    line-height: 52px;
    width: 287px;
    margin-bottom: 25px;
    background-color: white;
    font-size: 16px;
    border-radius: 4px;
    height: 52px;
}
.user-setting-wrapper .content-wrapper .settingInput > div {
    border-radius: 4px;
    height: 52px;
}
.user-setting-wrapper .content-wrapper .settingInput label {
    font-size: 14px;
    line-height: 10px;
}

.user-setting-wrapper .userSetting_passwordButton {
    width: 200px;
    height: 48px;
    border-radius: 10px;
    background: linear-gradient(45deg, #d96427 30%, #ee9d4f 90%);
}

.user-setting-wrapper .userSetting_passwordButton:hover {
    color: white;
    background-color: linear-gradient(45deg, #d96427 30%, #ee9d4f 90%);
}

@media screen and (max-width: 768px) {
    .user-setting-wrapper .content-wrapper .settingInput {
        width: 100%;
    }
    .user-setting-wrapper {
        margin: 0 2rem;
    }
    .user-setting-wrapper .userSetting_saveButton {
        width: auto;
    }
    .user-setting-wrapper .userSetting_saveButton span {
        margin: 0 1rem;
    }
}

.react-datepicker__header {
    background-color: #fff !important;
    border: none !important;
}

.react-datepicker__triangle {
    display: none !important;
}

.react-datepicker {
    gap: 24px;
    background: #ffffff;
    border: 1px solid #c6cbcc !important;
    box-shadow: 0px 4px 8px rgba(1, 35, 44, 0.1);
    border-radius: 8px !important;
    padding: 18px;
}

.react-datepicker__current-month {
    font-family: 'Spline Sans' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 150%;
    color: #1a1a1a !important;
}

.react-datepicker__day-name {
    font-family: 'Spline Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 115% !important;
    color: #00c57b !important;
}

.react-datepicker__day--selected {
    border-radius: 50% !important;
    background-color: #00c57b !important;
    color: #fff !important;
}

.react-datepicker__day {
    font-family: 'Spline Sans' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1a1a1a;
}

.react-datepicker__day-names {
    margin-top: 14px;
    margin-bottom: 4px !important;
}

.react-datepicker__navigation--next {
    right: 18px !important;
    top: 24px !important;
}

.react-datepicker__navigation--previous {
    left: 18px !important;
    top: 24px !important;
}

.react-datepicker__input-container input {
    background: #ffffff;
    border: 1px solid #c6cbcc;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 12px 12px 16px;
    width: 100%;
    height: 48px;
}

.reset-password-redirect .redirect-link {
    color: #00c57b !important;
    font-weight: 400;
    cursor: pointer;
}
