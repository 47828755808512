.play-icon {
  background: #ffffff;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 36px);
  opacity: 0;
  transition: 800ms opacity;
}
.fullscreen-icon {
  position: absolute;
  bottom: 36px;
  color: white;
  cursor: pointer;
}
#videoContainer:fullscreen .fullscreen-icon {
  right: 60px;
  bottom: 40px;
}
#videoContainer:fullscreen .fullscreen-icon svg {
  width: 32px;
  height: 32px;
}
.play-icon .play-icon__ic {
  color: #234099;
}
.tracking-icon {
  cursor: pointer;
}
.tracking-icon.active {
  color: gold;
}

.tracking-icon-container{
  padding: 0 !important;
  width: 96px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.tracking-icon-container button {
  padding: 0 !important;
}

section .error-video {
  width: 100%;
  height: 450px;
  color: #aaaaaa;
  background: #fff;
  text-align: center;
  position: absolute;
  top: 0px;
}
.error-video > p {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #aaaaaa;
}

.placeholder-context > p {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  color: white;
}