
.safety-center-wrapper {}
.react-player-event {
    background-color:black;
}
.analytic, .risk-analytic {
    width: 100%;
}
.analytic .analytic__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #234099;
}
.title {
    font-size: 24px;
    font-family: "Spline Sans";
    color: #234099;
    font-weight: normal;
}
.analytic .analytic__top .title {
    font-size: 24px;
    font-family: "Spline Sans";
    font-weight: normal;
}
.analytic .analytic__top .shapes {
    font-size: 14px;
    border: 1px solid #DCDFED;
    background: #DCDFED;
    border-radius: 8px;
    display: flex;
    position: relative;
}
.analytic .analytic__top .shapes .shape {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding-top: 3px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 600;
    z-index: 2;
}
.analytic .analytic__top .shapes .shapes__last-30-days {

}
.analytic .analytic__top .shapes .shapes_last-7-days {

}
.analytic .analytic__top .shapes .shapes__last_24h {

}
.analytic .analytic__top .shapes .shapes__active {
    border: 1px solid #fff;
    box-sizing: border-box;
    position: absolute;
    width: 120px;
    height: 30px;
    background: #fff;
    top: 0;
    left: 0;
    border-radius: 8px;
    z-index: 1;
    transition: left 500ms;
}

.analytic__bottom {
    margin: 30px 0;
}
.info_analytic {
    cursor: pointer;
}
.timeline {
    width: 94%;
}

.timeline .timeline__top {
    align-items: center;
    justify-content: space-between;
    color: #234099;
    padding: 13px 0px;
}

.timeline .timeline__top .title {
    font-size: 24px;
    font-family: "Spline Sans";
    font-weight: normal;
}

.timeline .timeline__top .shapes {
    font-size: 14px;
    border: 1px solid #DCDFED;
    background: #DCDFED;
    border-radius: 8px;
    display: flex;
    position: relative;
}

.timeline .timeline__top .shapes .shape {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding-top: 3px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 600;
    z-index: 2;
}

.timeline .timeline__top .shapes .shapes__saved {

}

.timeline .timeline__top .shapes .shapes_all {

}

.timeline .timeline__top .shapes .shapes__active {
    border: 1px solid #fff;
    box-sizing: border-box;
    position: absolute;
    width: 80px;
    height: 30px;
    background: #fff;
    top: 0;
    left: 0;
    border-radius: 8px;
    z-index: 1;
    transition: left 500ms;
}

.timeline .timeline__bottom {
    margin-top: 26px;
}

.events {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.events .events__sorts {
    display: flex;
}
.events .events__sorts .MuiSelect-select {
    font-family: "Spline Sans";
    font-weight: normal;
    color: rgb(60, 72, 88);
}
.events .events__sorts .sort {}
.events .events__sorts .sort:nth-child(1) {
    margin-right: 25px;
}
.events .events__sorts .sort:nth-child(2),
.events .events__sorts .sort:nth-child(3),
.events .events__sorts .sort:nth-child(4)  {
    margin-right: 20px;
 }
.events .events__sorts .sort .sort__label {
    margin-bottom: 0;
    font-weight: 600;
}
.events .events__sorts .sort .sort__select {
    min-width: 120px;
    min-height: 35px;;
}
.events .events__sorts .sort .sort__select::before {
    border-bottom: 1.5px solid rgb(60, 72, 88);
}

.sort__select .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
}

.sort__select .MuiSelect-selectMenu .parent-name {
    margin-bottom: 0;
}

.sort__select .MuiSelect-selectMenu .dot {
    width: 4px;
    height: 4px;
    margin: 0 5px;
    font-weight: 900;
    border-radius: 50%;
    background-color: #4C4C4C;
}

.sort__select .MuiSelect-selectMenu .name {
    margin-bottom: 0;
    color: #4C4C4C;
    font-family: "Spline Sans";
    font-weight: normal;
    font-size: 16px;
}

.select-item__event-type {
    display: flex;
    align-items: center;
}

.select-item__event-type .parent-name {
    margin-bottom: 0;
}

.select-item__event-type .dot {
    width: 4px;
    height: 4px;
    margin: 0 5px;
    font-weight: 900;
    border-radius: 50%;
    background-color: #4C4C4C;
}
.select-item.unused,
.select-item.unused label,
.select-item.unused p,
.select-item.unused span,
.select-item__event-type.unused,
.select-item__event-type.unused label,
.select-item__event-type.unused p,
.select-item__event-type.unused span{
    color: lightgrey;
}
.select-item__event-type.unused .dot {
    background-color: lightgrey;
}
.select-item__event-type .name {
    margin-bottom: 0;
    color: #4C4C4C;
    font-family: "Spline Sans";
    font-weight: normal;
}
.timeline .timelineContainer {
    position: relative;
    padding: 12px 2px 0px 2px;
    overflow: hidden;
}
.timeline .timelineContainer .dropShadow{
    background-color:#234099;
    opacity: .1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


.indiana-scroll-container--hide-scrollbars {
    overflow: auto !important;
    -ms-overflow-style: auto !important;
    scrollbar-width: auto !important;
}
.indiana-scroll-container--hide-scrollbars::-webkit-scrollbar {
    display: initial !important;
    height: initial !important;
    background: initial !important;
    -webkit-appearance: initial !important;
}
.timeline_container {
    width: 100%;
    height: 336px;
    overflow-y: scroll !important;

    overflow: auto !important;
    -ms-overflow-style: auto !important;
    scrollbar-width: auto !important;
}


.timeline_container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}


.timeline_container::-webkit-scrollbar {
    width: 12px !important;
    background-color: #F5F5F5;
    margin-right:5px;
}

.timeline_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #efefef;
}

.timeline_wrapper {
    width: 100%;
    position: relative;
}
ul.timeLineBoard ,
ul.timeLineBoard li
{
    list-style: none;
    padding: 0px;
    width: 100%;
    margin: 0px;
    z-index: 1;
}
ul.timeLineBoard li {
    border-bottom: 1px solid rgba(151,151,151, .3);
    height: 42px;
    cursor: pointer;
}
ul.timeLineBoard li:hover {
    background-color: #fff1ec;
}
ul.timeLineBoard li.timelineRow .timelineHour {
    width: 25%;
    line-height: 40px;
    text-align: center;
}
ul.timeLineBoard li.timelineRow .timelineClips {
    width: 75%;
}
.timelineContainer .timeline_clip {
    position: absolute;
    background-color: white;
    width: 5px;
    cursor: pointer;
    z-index: 2;
    border-radius: 2px;
}
.timelineContainer .timeline_clip:hover {
    box-shadow: 0 0 6px grey;
}


.timelineContainer .timeline_event {
    position: absolute;
    background-color: orange;
    height: 7px;
    width: 7px;
    border-radius: 3.5px;
    cursor: pointer;
    z-index: 3;
    overflow: hidden;
}

.timelineContainer .timeline_event_dot_0 {
    background-color: orange;
}
.timelineContainer .timeline_event_dot_0:hover {
    box-shadow: 0 0 6px orange;
}
.timelineContainer .timeline_event_dot_1 {
    background-color: #048A81;
}
.timelineContainer .timeline_event_dot_1:hover {
    box-shadow: 0 0 6px #048A81;
}
.timelineContainer .timeline_event_dot_2 {
    background-color: #06D6A0;
}
.timelineContainer .timeline_event_dot_2:hover {
    box-shadow: 0 0 6px #06D6A0;
}
.timelineContainer .timeline_event_dot_3 {
    background-color: #54C6EB;
}
.timelineContainer .timeline_event_dot_3:hover {
    box-shadow: 0 0 6px #54C6EB;
}
.timelineContainer .timeline_event_dot_4 {
    background-color: #8A89C0;
}
.timelineContainer .timeline_event_dot_4:hover {
    box-shadow: 0 0 6px #8A89C0;
}
.timelineContainer .timeline_event_dot_5 {
    background-color: #CDA2AB;
}
.timelineContainer .timeline_event_dot_5:hover {
    box-shadow: 0 0 6px #CDA2AB;
}
.timelineContainer .timeline_event_dot_6 {
    background-color: #FF7477;
}
.timelineContainer .timeline_event_dot_6:hover {
    box-shadow: 0 0 6px #FF7477;
}
.timelineContainer .timeline_event_dot_7 {
    background-color: #7BE0AD;
}
.timelineContainer .timeline_event_dot_7:hover {
    box-shadow: 0 0 6px #7BE0AD;
}
.timelineContainer .timeline_event_dot_8 {
    background-color: #003F91;
}
.timelineContainer .timeline_event_dot_8:hover {
    box-shadow: 0 0 6px #003F91;
}
.timelineContainer .timeline_event_dot_9 {
    background-color: #CE4760;
}
.timelineContainer .timeline_event_dot_9:hover {
    box-shadow: 0 0 6px #CE4760;
}
.timeline .secondaryExpandedTitle{
    color: #a4aab2;
    position: absolute;
    right: 45px;
    font-size: 12px;
    margin-top: 2px;
}
.reset-filter-events {
    color: #445b6d;
    cursor: pointer;
    margin-left: 8px;
    font-size: 15px !important;
}

.popup-comment-list {
    width: 300px;
    display: block;
    max-height: 340px;

    overflow: auto !important;
    -ms-overflow-style: auto !important;
    scrollbar-width: auto !important;
}

.popup-comment-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}


.popup-comment-list::-webkit-scrollbar {
    width: 12px !important;
    background-color: #F5F5F5;
    margin-right:5px;
}

.popup-comment-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,.3);
    background-color: #efefef;
}

.popup-comment-list  span {
    font-size: 14px;
}
.popup-comment-list p {
    max-width: initial;
    white-space: initial;
    text-overflow: initial;
    font-style: 13px;
    color: black;
}
