.Toastify__toast {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  color: black;
  border: 1px solid #e0e0e0;
  opacity: 1 !important;
  padding: 11px 14px;
}
.Toastify__toast .toast-bold {
  font-family: 'Spline Sans';
}
.Toastify__toast .toast-title {
  font-size: 16px;
}
.Toastify__toast .toast-message {
  font-size: 12px;
}

.video-review-container {
  position: absolute;
  width: 100%;
  top: 130px;
}
.fullscreen-video-container {
  width: 100vw;
  top: 120px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.fullscreen-video-container #player {
  background-color: #6c757d;
}
.video-review-filter {
  position: absolute;
  top: 50px;
  left: 100px;
  width: 300px;
}

.video-review-filter .video-review-filter-title {
  font-size: 32px;
}

.video-review-filter .video-filter-title {
}

.video-review-filter .video-review-filter-container {
  opacity: 0.7;
  border-radius: 5px;
  margin-top: 20px;
}
.video-review-filter .filter-line {
  width: 100%;
}
.video-review-filter .filter-line .MuiSelect-selectMenu {
  font-family: 'Spline Sans';
}

.video-review-filter .MuiInput-underline:before {
}
.video-review-filter .MuiInput-underline:hover:not(.Mui-disabled):before {
}
.video-reviews-filter-container .video-review_input-pin-container,
.video-reviews-filter-container div,
.video-reviews-filter-container input {
  cursor: pointer;
}

.video-reviews-filter-container {
  width: 100%;
  position: relative;
}
.video-reviews-filter-container .filter-title-content {
  font-size: 16px;
  color: #252626 !important;
  font-family: 'Spline Sans';
}
.video-reviews-filter-container .filter-title-label {
  font-size: 12px;
}
.video-reviews-filter-container .mg-df {
  margin-top: 20px;
}
.video-reviews-filter-container .sort {
  margin-top: 20px;
  width: 100%;
}
.video-reviews-filter-container .sort > div {
  font-family: 'Spline Sans';
  font-size: 14px;
  border-radius: 8px;
}

.video-reviews-filter-container .videoReview_SearchButton {
  width: 100%;
  margin-top: 24px;
  background: #00C57B;
  border-radius: 100px;
  padding: 8px 32px;
  height: 40px;
  color: #FFFFFF;
}

.video-reviews-filter-container .videoReview_SearchButton > span {
  font-family: 'Spline Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  text-transform: capitalize;
}

.video-reviews-filter-container .videoReview_CreateEventButton {
  color: #00C57B;
  border: 1px solid #C6CBCC;
  border-radius: 100px;
  width: 100%;
  margin-top: 12px;
  font-family: 'Spline Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  text-transform: capitalize;
  height: 40px;
}

.video-reviews-filter-container .videoReview_CreateEventButton:hover{
  border: 1px solid #00c57b;
}

.video-reviews-filter-container .videoReview_SearchButton:hover {
  background: #007045;
}

.video-reviews-filter-container .videoReview_CreateEventActionButton {
  width: 100%;
  margin: 5% 0%;
  box-shadow: none;
}
.video-reviews-filter-container .videoReview_CreateEventActionButton span {
  text-transform: none;
  font-family: 'Spline Sans';
}
.video-reviews-filter-container .videoReview_CancelCreateEventActionButton {
  width: 100%;
  margin: 0% 0%;
  box-shadow: none;
}
.video-reviews-filter-container .videoReview_CancelCreateEventActionButton span {
  text-transform: none;
  font-family: 'Spline Sans';
}

.video-reviews-filter-container #video-review_input-pin-label,
.video-reviews-filter-container #video-review_input-description-label {
  color: black;
  font-size: 14px;
  text-transform: none;
}
.video-reviews-filter-container #video-review_input-description {
 padding: 0;
}
.video-reviews-filter-container .replace-pin {
  position: absolute;
  right: 18px;
  top: 38px;
  font-size: 16px;
  color: #1A1A1A
}
.video-reviews-filter-container .create-event_select-area-note {
  font-size: 12px;
  color: #3c4858;
}
.video-reviews-filter-container .create-event_select-area-note b {
  font-family: 'Spline Sans';
}
#videoReviewContainer {
  margin-left:  24px;
  width: 100%;
}

.video-container-no-video {
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  height:  calc(100vh - 73px - 48px - 70px);
  width: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  flex-direction: column;
}

.video-container-no-video h6{
  font-family: 'Spline Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 135%;
  color: #949899;
  margin-top: 32px;
  text-transform: none;
}

.video-review-textfield:focus, .video-review-textfield:active{
  outline: none;
}

.video-container-no-video p{
  font-family: 'Spline Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #949899;
}

.video-container {
  position: relative;
}
.video-container #player {
  background-color: black;
}
.video-container .time-slider {
  user-select: none;
  width: 84%;
  height: 4px;
  border-radius: 1px;
  background: grey;
  transition: width 0.1s;
  cursor: pointer;
  position: absolute !important;
  left: 8%;
  bottom: 50px;
  overflow: hidden;
}
.video-container .slide-handle {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #f2652f;
  border-radius: 3px;
  transition: transform 0.2s;
  margin-top: -1px;
  top: 0px;
  cursor: pointer;
  margin-left: -2px;
}
.video-container .time-slider .slide-handle:hover,
.video-container .time-slider:hover .slide-handle {
  transform: scale(1.5);
}

.video-container .slider-bar {
  position: absolute;
  background: #f2652f;
  border-radius: 4px;
  height: 4px;
}
.video-container .current-time {
  position: absolute;
  left: 1%;
  color: #adadad;
  font-size: 14px;
  bottom: 40px;
}
.video-container .max-time {
  position: absolute;
  right: 1%;
  color: #adadad;
  font-size: 14px;
  bottom: 40px;
}
.video-container .time-slider .played-time {
  position: absolute;
  color: #adadad;
  font-size: 14px;
  top: 10px;
  opacity: 0;
}
.video-container .time-slider:hover .played-time {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}
.time-slider-container {
  position: absolute;
  width: 100%;
  height: 80px;
  z-index: 10;
  background-image: linear-gradient(transparent, black);
  bottom: 0px;
  border-radius: 8px;
}
.time-slider-container.fullscreen {
  top: initial;
  bottom: 0px;
}
.time-slider-container .isplay-icon {
  color: white;
  left: 18px;
  position: absolute;
  bottom: 10px;
  cursor: pointer;
}
.time-slider-container .isfullscreen-icon {
  position: absolute;
  color: white;
  right: calc(8% - 42px);
  bottom: 15px;
  cursor: pointer;
  font-size: 1.5rem;

}
.time-slider-container .sound-container {
  position: absolute;
  color: white;
  right: calc(8% - 5px);
  bottom: 15px;
  width: 200px;
  text-align: right;
}
.time-slider-container .sound-icon {
  color: white;
  display: inline-block;
  cursor: pointer;
  font-size: 25px;
}
.time-slider-container .sound-slider {
  color: orange;
  width: 0px;
  padding-bottom: 3px;
  margin-left: 10px;
  transition: width 1s;
  opacity: 0;
}
.sound-container:hover .sound-slider {
  width: 100px;
  opacity: 1;
}
.video-review-title{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: #01232C;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 32px;
  background-color: #FFFFFF;
}
.video-review-filter-label{
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #1A1A1A;
  font-family: 'Spline Sans';

}
.video-review-container-grid {
  padding: 24px;
  height:  calc(100vh - 73px - 48px - 70px);
  background: #F8F9FA;
  display: flex;
  flex-direction: row;
}

.video-review-container-grid .video-review-label-input{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding-bottom: 5px;

}
.video-review-container-grid .risk-analytic-left {
  position: relative;
  min-height:  calc(100vh - 73px - 48px - 70px);
  flex:30%;
  background: #FFFFFF;
  border-radius: 8px;
}
.video-review-container-grid .risk-analytic-left .create-event-buttons {
  position: absolute;
  width: 100%;
  bottom: 10px;
}

.grey-text > div {
  color: grey !important;
}

.video-indicator-container {
  text-align: right;
  right: 0px;
  width: 100%;
  background: transparent;
  height: 5px;
}

.video-container {
  position: relative;
  border-radius:8px ;
}

.drawer-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-icon{
  width: 24px !important;
  height: 24px !important;
  top: auto;
  font-size: 1.5rem;
}

.icon{
  width: 20px !important;
  height: 20px !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  content: "";
  background-size:18px  20px;
  background-size: 0% 0%;
  width: 18px;
  height: 20px;
  background-size: contain;
  z-index: -1;
  transition: background-size .5s, color .5s;
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
  padding: 14px;
  border-radius: 50%;
  background-size:24px  24px;
  background-position: center;
  transform: translate(11px ,0);
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator:active {
  padding: 14px;
  border-radius: 50%;
  transition: background .25s;
  background-size:24px  24px;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(11px ,0);
  cursor: pointer;
  transition:  background-color .25s;
}

input[type="time"]{
  z-index: 0;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .video-review-container-grid {
    flex-direction: column;
  }
  .video-review-container-grid .risk-analytic-left {
    max-width: 768px;
    min-width: 100%;
  }
  #videoReviewContainer{
    margin: 24px 0 ;
  }
}
