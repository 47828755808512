.chart-container {
    padding-top: 60px;
}
.risk-filter-label {
    font-family: 'Spline Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #1A1A1A;
}

.sort .MuiSelect-selectMenu {
    color: #333333 !important;
}
.sort .grey-text .MuiSelect-selectMenu {
    color: grey !important;
}
.filter-input {
    margin-top: 17px !important;
}
.filter-input input {
    cursor: pointer !important;
}
.filter-checkbox-icon-container {
    margin-top: 17px;
    color: #333333 !important;
}
.filter-checkbox-icon-container span {
    font-size: 16px;
    color: #333333;
    font-family: 'Spline Sans';
    font-weight: normal;
}
.filter-checkbox-icon-container .MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
}
.checkboxIcon {
    color: #284394;
}

.chart-legend {
    position: absolute;
    right: 0px;
    top: 0;
}
.chart-legend.grid {
    top: 25px;
}
.chart-legend li {
    display: inline-block;
    width: auto;
}
.chart-legend li > div {
    display: inline-block;
}
.chart-legend li > svg {
    margin-bottom: 5px;
}
.chart-title {
    position: absolute;
    top: 15px;
    left: 5px;
    text-align: left !important;
}

.chart-legend.w165 {
    width: 165px;
}

.risk-filter-reviews .sort {
    float: left;
    font-size: 12px;
    min-width: 100%;
    margin: 0;
    margin-top: 15px;
}

.risk-analytic .reset-filter {
    margin-top: 0;
}

.risk-filter-reviews .filter-checkbox-icon-container span {
    font-weight: normal;
}

.risk-filter-reviews .MuiFormControlLabel-labelPlacementStart {
    margin-left: 5px;
}

.wrap-img-switch {
    width: 220px;
}

.img-label-switch {
    width: 30px;
    display: inline-block;
    padding: 5px;
}

.img-label-switch img {
    width: 100%;
    height: 100%;
}

.day-of-week-container {
    width: 99%;
    display: flex;
    align-items: stretch;
    background-color: rgba(0, 197, 123, 0.05);
    border-radius: 5px;
    color: #00C57B;
    font-weight: 600;
    height: 32px;
}

.day-of-week-container .week-day {
    width: calc(100% / 7);
    text-align: center;
    cursor: pointer;
    background-color: transparent;
    padding-top: 2px;
    border: 1px solid #00C57B;
    display: flex;
    align-items: center;
    justify-content: center;
}

.day-of-week-container .week-day.selected {
    background-color: white;
    border: 1px solid #C6CBCC;
    color: #949899;
}

.day-of-week-container .week-day:first-child {
    border-radius: 5px 0px 0px 5px;
}

.day-of-week-container .week-day:last-child {
    border-radius: 0px 5px 5px 0px;
}
.map-area-module {
    width: 100%;
    height: 100%;
}

.map-area-module .map-area-drawing {
    width: 85%;
    height: 70%;
    margin-top: 10px;
    position: relative;
}
.map-area-module .map-area-no-data {
    background-color: rgba(245, 246, 250, 1);
    width: 89%;
    height: 80%;
    margin-top: 10px;
}
.map-area-module-right #heatmap-legend {
    position: absolute;
    height: 100%;
    width: 40px;
    background: #FFFFFF;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.map-area-module-right #heatmap-legend > span {
    font-size: 12px;
}
.map-area-module-right #heatmap-legend span.legend-title {
    font-family: 'Spline Sans';
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #252626;
    font-style: normal;
}
.map-area-module-right #heatmap-legend span.legend-unit {
    font-family: 'Spline Sans';
    font-weight: normal;
    color: #646576;
}
.map-area-module-right #heatmap-legend .legend-bar {
    width: 8px;
    height: 100%;
}
.map-area-module-right #heatmap-legend .legend-bar-level {
    display: inline-flex;
    flex-direction: column;
    height: 150px;
    text-align: right;
    font-size: 12px;
    justify-content: space-between;
    color: #646576;
    float: left;
}

.map-area-module-right #heatmap-legend .top-bottom {
    height: 15%;
    width: 50%;
}

.map-area-module-right #heatmap-legend .top-bottom .btn-plus-minus {
    background-color: white;
    box-shadow: 0px 4px 15px rgba(31, 47, 124, 0.12);
    border-radius: 2px;
}

.map-area-no-data {
    background-color: rgba(245, 246, 250, 1);
    width: 85%;
    height: 65%;
    margin-top: 24px;
}
